import { AnnotationFilterListType } from "../modules/patient-order/report/models/report-view.model";
import { MeasurementNoteResponse } from "./annotation";
import { Patient } from "./patient";

/* eslint-disable @typescript-eslint/naming-convention */
export enum SweepPatterns {
  UP_AND_DOWN = "UP_AND_DOWN",
  LEFT_AND_RIGHT = "LEFT_AND_RIGHT",
  TOP_LEFT_TO_RIGHT_HIP = "TOP_LEFT_TO_RIGHT_HIP",
  LEFT_HIP_TO_TOP_RIGHT = "LEFT_HIP_TO_TOP_RIGHT",
  ADDITIONAL = "ADDITIONAL",
}

export const intakeFormSteps = [
  "patient-info",
  "pregnancy-info",
  "indications-info",
  "appointment-info",
  "summary",
];

export interface PatientOrderResponse {
  institutionId: string;
  patientId: string;
  clinicId: string;
  accessionNumber: string;
  patientOrderId: string;
  procedureId: string;
  // orderState: string;
  createdDate: string;
  postDicomOrderId: string;
  studyId: string;
  sonographer: string;
  driver: string;
  orderData: PatientOrderForm;
}

export interface PatientOrderForm {
  id: string | null;
  currentSweepDirection: SweepPatterns | null;
  totalFrameCount: null;
  regionLocationMaxY1: null;
  regionLocationMinY0: null;
  regionLocationMaxX1: null;
  regionLocationMinX0: null;
  physicalDeltaX: null;
  physicalUnitsXDirection: null;
  columnCount: null;
  physicalDeltaY: null;
  physicalUnitsYDirection: null;
  rowCount: null;
  status: string;
  lastStepCompleted: string | null;
  institutionId: string;
  patientUserId: string | null;
  driverUserId: string | null;
  sonographerUserId: string | null;
  physicianUserId: string | null;
  clinicId: string | null;
  clinicName: string | null;
  examRoomId: string | null;
  examRoomName: string | null;
  visitPurpose: null | string;
  visitType: null | "SCREENING" | "FOLLOW_UP";
  bpp: boolean | null;
  genderRevealDesired: null | boolean;
  bmi: null | number;
  height: null | number; // Height in inches
  weight: null | number;
  bpDiastolic: null | number;
  bpSystolic: null | number;
  pregnancyStage: null | string; // early-pregnancy | first-trimester | second-trimester | third-trimester
  estimatedDueDate: null | number; // Format: YYYYMMDD
  prePregnancyWeight: null | number;
  prePregnancyBmi: null | number;
  dueDateMethodology:
    | null
    | "Last Menstrual Period"
    | "Ultrasound"
    | "Conception Date"
    | "Baseline";
  lastMenstrualPeriod: null | number; // Format: YYYYMMDD
  numCSections: null | number;
  numGreaterThan37Deliveries: null | number;
  numLessThan37Deliveries: null | number;
  numLiveBirths: null | number;
  numMiscarriagesAbortions: null | number;
  ultrasoundMethod:
    | null
    | "TRANSVAGINAL"
    | "TRANSABDOMINAL"
    | "TRANSABDOMINAL_TRANSVAGINAL";
  requestingPhysician: null | string;
  referringPhysician: null | string;
  createdOn: null | Date;
  updatedOn: null | Date;
  fetalMovement: null | boolean;
  fetalHeartRate: number;
  echoIndications: any; // TODO-REFACTOR: Figure out this type
  preNatalScreeningTestResult: null | "Negative" | "Positive" | "Not Conducted";
  leepProcedureConducted: null | boolean;
  // NEW PROPERTIES
  numGestations: number | null;
  hadUltrasound: boolean | null;
  ivfConception: boolean | null;
  ivfDate: number | null;
  deleted: boolean | null;
  driverFullName: null | string;
  assignedSonographerFullName: null | string;
  physicianFullName: null | string;
  impressionNotesLatched: boolean;
  finalizedOn: null | Date;
  facilityName: null | string;
  facilityAddress1: null | string;
  facilityAddress2: null | string;
  facilityCity: null | string;
  facilityState: null | string;
  facilityZipCode: null | string;
  reportConfig: AnnotationFilterListType[];
  patient?: Patient;
  //T1-3523's CODE
  pacsStudy: null | boolean;
}

export const newPatientOrderForm = (): PatientOrderForm => {
  return {
    id: null,
    currentSweepDirection: null,
    totalFrameCount: null,
    regionLocationMaxY1: null,
    regionLocationMinY0: null,
    regionLocationMaxX1: null,
    regionLocationMinX0: null,
    physicalDeltaX: null,
    physicalUnitsXDirection: null,
    columnCount: null,
    physicalDeltaY: null,
    physicalUnitsYDirection: null,
    rowCount: null,
    status: "",
    lastStepCompleted: null,
    institutionId: "",
    patientUserId: null,
    driverUserId: null,
    sonographerUserId: null,
    physicianUserId: null,
    clinicId: null,
    clinicName: null,
    examRoomId: null,
    examRoomName: null,
    visitPurpose: null,
    visitType: null,
    bpp: null,
    genderRevealDesired: null,
    bmi: null,
    height: null,
    weight: null,
    bpDiastolic: null,
    bpSystolic: null,
    pregnancyStage: null,
    estimatedDueDate: null,
    prePregnancyWeight: null,
    prePregnancyBmi: null,
    dueDateMethodology: null,
    lastMenstrualPeriod: null,
    numCSections: null,
    numGreaterThan37Deliveries: null,
    numLessThan37Deliveries: null,
    numLiveBirths: null,
    numMiscarriagesAbortions: null,
    ultrasoundMethod: null,
    requestingPhysician: null,
    referringPhysician: null,
    createdOn: null,
    updatedOn: null,
    fetalMovement: null,
    fetalHeartRate: null,
    echoIndications: null,
    preNatalScreeningTestResult: null,
    leepProcedureConducted: null,
    numGestations: null,
    hadUltrasound: null,
    ivfConception: null,
    ivfDate: null,
    deleted: false,
    driverFullName: null,
    assignedSonographerFullName: null,
    physicianFullName: null,
    impressionNotesLatched: false,
    finalizedOn: null,
    facilityName: null,
    facilityAddress1: null,
    facilityAddress2: null,
    facilityCity: null,
    facilityState: null,
    facilityZipCode: null,
    reportConfig: [],
    //T1-3523's CODE
    pacsStudy: null,
  };
};

export interface SweepsByDirection {
  sweeps: Sweep[];
  name: SweepPatterns;
}

export interface FrameConfig {
  hflip: boolean;
  invert: boolean;
  labelmap: boolean;
  pixelReplication: boolean;
  rotation: number;
  scale: number;
  scaleLatched?: boolean;
  translation: { x: number; y: number };
  vflip: boolean;
  voi: { windowCenter: number; windowWidth: number };
  origScale?: number;
  defaultScaleAtSave?: number;
}

export interface Sweep {
  id: string;
  index: number;
  thumbUrl: string;
  metaData: {
    regionLocationMaxY1: number;
    regionLocationMinY0: number;
    regionLocationMaxX1: number;
    regionLocationMinX0: number;
    physicalDeltaX: number;
    physicalUnitsXDirection: number;
    columnCount: number;
    physicalDeltaY: number;
    physicalUnitsYDirection: number;
    rowCount: number;
  };
  urls: {
    url: string;
    config: FrameConfig;
    hasAnnotation?: boolean;
    sweepIndex?: number;
    frameId?: string;
  }[];
  direction?: string;
  quality: number;
  viewedByUser: string[];
  hasBeenViewedByUser: boolean;
}

export interface SweepLoaded extends Sweep {
  direction: string;
  loadSuccess: string[];
  loadFailed: string[];
  notes?: MeasurementNoteResponse[];
  fauxId?: number; //user in the galleryView for refesh
}

export interface SweepByIdResponse {
  id: string;
  institutionId: string;
  studyId: string;
  direction: string;
  index: number;
  s3Key: string;
  numFrames: number;
}

export interface SweepsByIdAsKey {
  [key: string]: Sweep;
}

export interface SweepsLoadedByIdAsKey {
  [key: string]: SweepLoaded;
}

/* eslint-enable @typescript-eslint/naming-convention */

export interface Patient {
  id?: string;
  lastName: string;
  firstName: string;
  maidenName?: string;
  email: string;
  phoneNumber: string;
  dob: number | null; // Format: YYYYMMDD
  institutionId: string;
  sex?: string;
  patientId?: string;
  zipcode: string | null;
  insuranceProvider: string | null;
  notes: string;
}

export const newPatient = (): Patient => {
  return {
    lastName: "",
    firstName: "",
    maidenName: " ",
    email: "",
    phoneNumber: "",
    dob: null,
    institutionId: "",
    zipcode: null,
    insuranceProvider: null,
    notes: null,
  };
};

export interface PatientListResponse {
  patients: Patient[];
  status: boolean;
  totalItem: number;
  totalPage: number;
  pageSize: string;
  currentPage: string;
}

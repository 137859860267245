import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, map, retryWhen, shareReplay } from "rxjs/operators";
import { BehaviorSubject, Observable, ReplaySubject, throwError } from "rxjs";
import { studyStatusList } from "src/app/models/constants";
import { JWTTokenService } from "src/app/core/services/jwt.service";
import { EventSourcePolyfill } from "event-source-polyfill";
import { TenantService } from "src/app/core/services/tenant.service";
import {
  Clinic,
  ExamRoom,
  newPatientOrderForm,
  PatientOrderForm,
  PatientOrderInfoBrief,
  PatientOrderStatusResponse,
  SonographerReportResponse,
  SweepPatterns,
  SweepsByDirection,
} from "src/app/models";
import { environment } from "src/environments/environment";
import { AlertService } from "./alert.service";
import { RxjsService } from "src/app/global/services/rxjs-service";
import {
  AnnotationFilterList,
  IntakeReport,
} from "src/app/modules/patient-order/report/models/report-view.model";
import { ApiPrediction, Prediction } from "src/app/models/predictions";

export interface StudyFramesObj {
  name: SweepPatterns;
  sweeps: {
    id: string;
    index: number;
    url: string[];
  }[];
}

interface LowResSweep {
  index: number;
  frames: string[];
}

@Injectable({ providedIn: "root" })
export class PatientOrderService {
  private navLinkSource = new BehaviorSubject<string>("Survey");
  navLinks$ = this.navLinkSource.asObservable();

  private reportStatusSource = new BehaviorSubject<boolean>(false);
  reportStatus$ = this.reportStatusSource.asObservable();

  private isSurveyCompleteSource = new BehaviorSubject<boolean>(false);
  isSurveyComplete$ = this.isSurveyCompleteSource.asObservable();

  updateSurveyCompleteStatus(isComplete: boolean): void {
    this.isSurveyCompleteSource.next(isComplete);

    const updatedNavLink = isComplete ? "Survey" : "Survey Incomplete";
    this.updateNavLink(updatedNavLink);

    const updatedReportStatus = isComplete ? true : false;
    this.updateReportStatus(updatedReportStatus);
  }

  updateReportStatus(newStatus: boolean): void {
    this.reportStatusSource.next(newStatus);
  }

  updateNavLink(newLink: string): void {
    this.navLinkSource.next(newLink);
  }

  public poStatus: BehaviorSubject<string>;
  private _pregnancyStage$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  public readonly pregStageObs: Observable<string> =
    this._pregnancyStage$.asObservable();

  private _sonographerClaim$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  public readonly sonographerClaimObs: Observable<string> =
    this._sonographerClaim$.asObservable();

  public _po$: BehaviorSubject<PatientOrderForm> =
    new BehaviorSubject<PatientOrderForm>(newPatientOrderForm());

  public readonly poObs: Observable<PatientOrderForm> =
    this._po$.asObservable();

  public dueDateMethodology: BehaviorSubject<
    | "Last Menstrual Period"
    | "Ultrasound"
    | "IVF Conception Date"
    | "Baseline"
    | null
  >;

  private _sweepsBS: ReplaySubject<SweepsByDirection[]>;
  public patientOrder: PatientOrderForm;
  public reportConfigMap: {
    [key: string]: { checked: boolean; name: string };
  } = {};

  public msgOptions: {
    autoClose: true;
    keepAfterRouteChange: false;
  };

  private baseUrl: string;

  constructor(
    private _http: HttpClient,
    private _jwt: JWTTokenService,
    private _alertService: AlertService,
    private _rxjsService: RxjsService,
    private _ts: TenantService
  ) {
    /**
     *  Tenant needs to be called in the function because we have endpoints
     *  that utilize these methods which haven't set the tenant URL prior
     *  to be called. So, when the constructor calls for the tenant it is null
     **/
    this.baseUrl = `${environment.bbApiUrl}${environment.studyPort}`;
    this.poStatus = new BehaviorSubject<string>("");
    this._sweepsBS = new ReplaySubject<SweepsByDirection[]>(1);
    this.dueDateMethodology = new BehaviorSubject<
      | "Last Menstrual Period"
      | "Ultrasound"
      | "IVF Conception Date"
      | "Baseline"
      | null
    >(null);
    this.reconnectFrequencySeconds = 1;
  }

  reconnectFrequencySeconds: number;

  get(): PatientOrderForm {
    if (typeof this.patientOrder?.reportConfig === "string") {
      this.patientOrder.reportConfig = JSON.parse(
        this.patientOrder.reportConfig
      );
    }
    return this.patientOrder || newPatientOrderForm();
  }

  // Can't set the input to PatientOrderForm because of report service setting it to IntakeReport
  set(po): void {
    this.patientOrder = po || newPatientOrderForm();
    if (!this.patientOrder.reportConfig)
      this.patientOrder.reportConfig = JSON.parse(
        JSON.stringify(AnnotationFilterList)
      );

    // If reportConfig is a JSON string parse it out
    if (typeof this.patientOrder.reportConfig === "string") {
      this.patientOrder.reportConfig = JSON.parse(
        this.patientOrder.reportConfig
      );
    }

    this.patientOrder.reportConfig
      .flatMap((section) => section.options)
      .forEach((option) => {
        this.reportConfigMap[option.id] = {
          checked: option.checked,
          name: option.name,
        };
      });

    this._po$.next(this.patientOrder);
  }

  setStatusValue(newStatus): void {
    this.poStatus.next(newStatus);
  }

  getStatusValue(): Observable<string> {
    return this.poStatus.asObservable();
  }

  setPregnancyStage(newStage): void {
    this._pregnancyStage$.next(newStage);
  }

  getPregnancyStageObs(): Observable<string> {
    return this.pregStageObs;
  }

  /**
   *
   * @param userId The user to be assigned as the Sonographer for the order
   * @param studyId Optional: Specify the studyId. Otherwise, will use this.patientOrder from patient-order service
   */
  async setClaimant(userId: string, studyId?: string): Promise<void> {
    try {
      await this.updateOrderField(studyId || this.patientOrder.id, [
        {
          name: "sonographerUserId",
          value: userId,
        },
      ]);
      this._sonographerClaim$.next(userId);
    } catch (error) {
      console.log("Error in setClaimant", error);
    }
  }

  getReportConfigMap(): {
    [key: string]: { checked: boolean; name: string };
  } {
    return this.reportConfigMap;
  }

  getClaimantObs(): Observable<string> {
    return this.sonographerClaimObs;
  }

  setDueDateMethodology(newMethod): void {
    this.dueDateMethodology.next(newMethod);
  }

  getDueDateMethodology(): Observable<string> {
    return this.dueDateMethodology.asObservable();
  }

  async updateOrderField(
    studyId: string,
    fields: { name: string; value: any }[]
  ): Promise<PatientOrderForm> {
    if (!studyId) {
      this.createOrder(this.patientOrder);
      return;
    }

    // Only update the fields that have a new value and if there are
    // not any new values then don't update
    fields.filter((f) => f.value !== this.patientOrder[f.name]);
    if (fields.length === 0) return;

    const url = `${this.baseUrl}/${this._ts.getTenant()}/study`;
    const body = { studyId, fields };

    const res = await this._http.patch(url, body).toPromise();

    // Update in this fashion so that we don't overwrite changes
    // that were made while this update call was waiting
    const updates: any = {};
    fields.forEach((f) => {
      updates[f.name] = f.value;
    });
    this.set({
      ...this.patientOrder,
      ...updates,
    });
    // this.setDueDateMethodology(res.dueDateMethodology);
    return res as PatientOrderForm;
  }

  // use the new updateStudyStatusField$ Observable
  updateStudyStatusField(studyId: string, status: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = {
        studyId,
        status,
      };
      if (status === "ORDER_COMPLETED") {
        // Get timezone for final report signature
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        body["timezone"] = tz.split("/").join("--"); // Replace slashes to pass as param in report URL
      }

      this._http
        .patch(`${this.baseUrl}/${this._ts.getTenant()}/study/status`, body)
        .toPromise()
        .then((res: PatientOrderForm) => {
          this.setStatusValue(res.status);
          return resolve(res);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  // TODO: this should move into its own institution(?) service
  getClinicNameById(clinicId: string): Promise<string> {
    const url = `${environment.bbApiUrl}${environment.institutionPort}/clinic/${clinicId}`;
    return new Promise((resolve) => {
      this._http
        .get(url)
        .toPromise()
        .then((res: Clinic[]) => {
          if (res && res[0]) {
            return resolve(res[0].name);
          }
          return resolve("");
        })
        .catch(() => {
          // TODO: Add this to logging service
          // console.log("Error getting Exam Room Name", error);
        });
    });
  }

  async triggerWorklist(studyId: string): Promise<boolean> {
    const url = `http://localhost:8080/study`;

    const body = {
      studyId,
    };

    const res = await this._http.post(url, body).toPromise();

    return !!res;
  }

  // TODO: this should move into its own institution(?) service
  getExamRoomNameById(examRoomId: string): Promise<string> {
    if (!examRoomId) return;
    const url = `${environment.bbApiUrl}${environment.institutionPort}/exam-room/${examRoomId}`;
    return new Promise((resolve) => {
      this._http
        .get(url)
        .toPromise()
        .then((res: ExamRoom) => {
          return resolve(res.name);
        })
        .catch(() => {
          // TODO: Add this to logging service
          // console.log("Error getting Exam Room Name", error);
        });
    });
  }

  getFramesByStudyId(studyId: string): Promise<SweepsByDirection[]> {
    const url = `${
      this.baseUrl
    }/${this._ts.getTenant()}/frame/study/${studyId}`;
    return new Promise((resolve) => {
      this._http
        .get(url)
        .toPromise()
        .then((res: SweepsByDirection[]) => {
          return resolve(res);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          // TODO: Add this to logging service
          // console.log("Error getting Exam Room Name", error);
        });
    });
  }

  getSweepsObservable(): Observable<SweepsByDirection[]> {
    return this._sweepsBS.asObservable();
  }

  setSweepsObservable(): Promise<SweepsByDirection[]> {
    // Set studyId if its passed in. This prevents us from passing it in everytime.
    // We will receive it on the first call by the resolver so this value should remain set throughout the life of the page.
    return new Promise((resolve, reject) => {
      if (!this.patientOrder.id) {
        reject();
      }
      this.getFramesByStudyId(this.patientOrder.id)
        .then((sweeps: SweepsByDirection[]) => {
          resolve(sweeps);
          this._sweepsBS.next(sweeps);
          return sweeps;
        })
        .catch(() => {
          this._sweepsBS.next([]);
          // TODO: Add this to logging service
          // console.log(
          //   "Error getting study notes in annotationService constructor.",
          //   error
          // );
        });
    });
  }

  checkStudyForUser(studyId, userId): Promise<any> {
    const url = `${
      this.baseUrl
    }/${this._ts.getTenant()}/study/checkstudy/${studyId}/user/${userId}`;
    return new Promise((resolve) => {
      this._http
        .get(url)
        .toPromise()
        .then((data) => {
          return resolve(data);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          throwError(error);
        });
    });
  }

  getPatientOrders(institutionId, userId): Observable<PatientOrderInfoBrief[]> {
    let url = `${this.baseUrl}/${this._ts.getTenant()}/study/`;
    if (institutionId) url += `institution/${institutionId}/user/${userId}`;
    return this._http.get(url).pipe(
      map((res: PatientOrderInfoBrief[]) => {
        return res;
      }),
      catchError((errorRes) => {
        this._alertService.error(
          `Error ${
            errorRes.statusCode ? errorRes.statusCode : ""
          }: An error was encountered, please refresh the page and try again.`,
          this.msgOptions
        );
        return throwError(errorRes);
      })
    );
  }

  getAllOrdersByUser(userId: string): Promise<PatientOrderForm[]> {
    const url = `${
      this.baseUrl
    }/${this._ts.getTenant()}/study/user/${userId}/patient`;
    return this._http
      .get(url)
      .pipe(
        catchError((err) => this._rxjsService.handleErrors(err)),
        map((data: PatientOrderForm[]) => {
          return data.map((d) => {
            if (typeof d.echoIndications === "string")
              d.echoIndications = d.echoIndications.split(",");
            return d;
          });
        })
      )
      .toPromise();
  }

  getMyActiveOrders(
    userId: string,
    status = ""
  ): Observable<PatientOrderStatusResponse> {
    let url = `${environment.bbApiUrl}${
      environment.patientPort
    }/${this._ts.getTenant()}/patientorders/search/mine/${userId}/`;
    if (status) url += status;
    return this._http.get(url).pipe(
      map((res: PatientOrderStatusResponse) => {
        return res;
      }),
      catchError((errorRes) => {
        // TODO: Add this to logging service
        return throwError(errorRes);
      })
    );
  }

  async createOrder(
    patientOrderForm: PatientOrderForm
  ): Promise<PatientOrderForm> {
    const poUrl = `${this.baseUrl}/${this._ts.getTenant()}/study`;
    const body = patientOrderForm;
    return this._http
      .post(poUrl, body)
      .pipe(catchError(this.handleError))
      .pipe(
        map((response: PatientOrderForm) => {
          this.set(response);
          return response;
        })
      )
      .toPromise();
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
      this._alertService.error(
        `Error ${
          error.status ? error.status : ""
        }: An error was encountered, please refresh the page and try again.`,
        this.msgOptions
      );
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this._alertService.error(
        `Error ${
          error.status ? error.status : ""
        }: An error was encountered, please refresh the page and try again.`,
        this.msgOptions
      );
      console.error(
        `Backend returned code ${error.status}, body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError("Something bad happened; please try again later.");
  }

  getPatientOrder(poID: string): Promise<PatientOrderForm> {
    const poUrl = `${this.baseUrl}/${this._ts.getTenant()}/study/${poID}`;
    return new Promise((resolve) => {
      this._http
        .get(poUrl)
        .toPromise()
        .then((data: PatientOrderForm) => {
          if (typeof data.echoIndications === "string")
            data.echoIndications = data.echoIndications.split(",");
          this.set(JSON.parse(JSON.stringify(data)));
          this.setStatusValue(data.status);
          this.setPregnancyStage(data.pregnancyStage);
          return resolve(data);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          console.log("Error in getPatientOrder", error);
          throwError(error);
        });
    });
  }

  //TODO:  MOVE TO ANOTHER SERVICE
  getPoStatus(
    institutionId: string,
    patientId: string,
    orderId: string
  ): Observable<PatientOrderInfoBrief> {
    const url = `${environment.bbApiUrl}${
      environment.patientPort
    }/${this._ts.getTenant()}/patientorders/institution/${institutionId}${
      environment.patientPort
    }/patient/${patientId}/order/${orderId}/status/`;
    return this._http.get<PatientOrderInfoBrief>(url);
  }

  /**
   * Used to check if the study is past a certain point.
   * @param statusToCheck The status to check against
   * @param studyStatus Current status of the study
   * @param equalToOrGreaterThan Can the statuses be equal to return true. Defaults to true.
   * @returns If the study is (at or) beyond the status given
   */
  isPastStatus(
    statusToCheck: string,
    studyStatus: string,
    equalToOrGreaterThan = true
  ): boolean {
    const studyIdx = studyStatusList.findIndex((i) => i.id === studyStatus);
    const toCheckIdx = studyStatusList.findIndex((i) => i.id === statusToCheck);
    if (equalToOrGreaterThan) {
      return studyIdx >= toCheckIdx;
    }
    return studyIdx > toCheckIdx;
  }

  //TODO:  MOVE TO ANOTHER SERVICE
  putSonographerForm(
    institutionId: string,
    orderId: string,
    patientId: string,
    wizard: string,
    form: string,
    formValues: any
  ): Observable<any> {
    return this._http.put(
      `${environment.bbApiUrl}${
        environment.patientPort
      }/${this._ts.getTenant()}/patientorders/institution/${institutionId}${
        environment.patientPort
      }/patient/${patientId}/order/${orderId}/sonographer/${wizard}/${form}`,
      formValues
    );
  }

  //TODO:  MOVE TO ANOTHER SERVICE
  putSonographerFormProperty(
    institutionId: string,
    orderId: string,
    patientId: string,
    wizard: string,
    form: string,
    property: string,
    value: any
  ): Observable<any> {
    return this._http.put(
      `${environment.bbApiUrl}${
        environment.patientPort
      }/${this._ts.getTenant()}/patientorders/institution/${institutionId}${
        environment.patientPort
      }/patient/${patientId}/order/${orderId}/sonographer/${wizard}/${form}/property/${property}`,
      { value }
    );
  }

  //TODO:  MOVE TO ANOTHER SERVICE
  deleteSonographerFormProperty(
    institutionId: string,
    orderId: string,
    patientId: string,
    wizard: string,
    form: string,
    property: string
  ): Observable<any> {
    return this._http.delete(
      `${environment.bbApiUrl}${
        environment.patientPort
      }/${this._ts.getTenant()}/patientorders/institution/${institutionId}${
        environment.patientPort
      }/patient/${patientId}/order/${orderId}/sonographer/${wizard}/${form}/property/${property}`
    );
  }

  //TODO:  MOVE TO ANOTHER SERVICE
  getSonographerReport(
    institutionId: string,
    orderId: string,
    patientId: string,
    diagnostic: string
  ): Observable<SonographerReportResponse> {
    return this._http
      .get(
        `${environment.bbApiUrl}${
          environment.patientPort
        }/${this._ts.getTenant()}/patientorders/institution/${institutionId}${
          environment.patientPort
        }/patient/${patientId}/order/${orderId}/sonographer/${diagnostic}${
          environment.diagnosticPort
        }/report`
      )
      .pipe(
        map((response) => {
          return response as SonographerReportResponse;
        })
      );
  }

  /**
   * Sends request to study service endpoint to generate a test sweep
   * for the given study. Returns true if sweep is generated.
   * Otherwise, returns false.
   * @param studyId
   * @returns boolean
   */
  triggerTestSweep(studyId: number | string): Promise<boolean> {
    const url = `${
      this.baseUrl
    }/${this._ts.getTenant()}/sweep/generate/study/${studyId}`;

    return new Promise((resolve, reject) => {
      this._http
        .post(url, {})
        .toPromise()
        .then((res: boolean) => {
          return resolve(res);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          console.log("Error in triggerTestSweep", error);
          reject(error);
        });
    });
  }

  deleteOrder(studyId: string): Observable<void> {
    const url = `${this.baseUrl}/${this._ts.getTenant()}/study/${studyId}`;
    return this._http.delete(url).pipe(
      map(() => {}),
      catchError((errorRes) => {
        this._alertService.error(
          `Error ${
            errorRes.statusCode ? errorRes.statusCode : ""
          }: An error was encountered, please refresh the page and try again.`,
          this.msgOptions
        );
        return throwError(errorRes);
      })
    );
  }

  getSweepsStream(poId: string): Observable<any> {
    return this.serverSentStream(
      `${this.baseUrl}/${this._ts.getTenant()}/sweep/study/${poId}/stream`
    );
  }

  async getPredictions(): Promise<Prediction[]> {
    try {
      const url = `${this.baseUrl}/${this._ts.getTenant()}/prediction/${
        this.patientOrder.id
      }`;
      const res = (await this._http.get(url).toPromise()) as ApiPrediction[];

      let predictions = [];
      if (res && res.length > 0) {
        predictions = res.map((p) => {
          let topPredictions = [];
          let selectedFrameIds = [];
          if (p.selectedFrameIds) {
            selectedFrameIds = JSON.parse(p.selectedFrameIds);
          }

          if (p.topPredictions) {
            topPredictions = JSON.parse(p.topPredictions)?.Priority;
            topPredictions = topPredictions.map((tp) => {
              const frameId =
                tp[2] === "0"
                  ? "0"
                  : Number.parseInt(tp[2].split(".")[0].replace(/^0+/, ""));

              return {
                id: tp[0],
                score: tp[1],
                url: `${environment.bbApiUrl}/${this.patientOrder.institutionId}/${this.patientOrder.id}/${tp[2]}`,
                frameId,
                isSelected: selectedFrameIds.includes(frameId),
              };
            });
          }

          return {
            ...p,
            sweepIndex: Number.parseInt(p.sweepIndex),
            selectedFrameIds,
            topPredictions,
          };
        });
      }

      return predictions;
    } catch (error) {
      this._alertService.error(
        `Error ${
          error.statusCode ? error.statusCode : ""
        }: An error was encountered, please refresh the page and try again.`,
        this.msgOptions
      );
    }
  }

  async setSelectedFrames(sweepIndex, frameIds): Promise<ApiPrediction> {
    try {
      const url = `${
        this.baseUrl
      }/${this._ts.getTenant()}/prediction/setSelectedFrames`;
      const body = {
        studyId: this.patientOrder.id,
        sweepIndex,
        frameIds,
      };

      return (await this._http.post(url, body).toPromise()) as ApiPrediction;
    } catch (error) {
      this._alertService.error(
        `Error ${
          error.statusCode ? error.statusCode : ""
        }: An error was encountered, please refresh the page and try again.`,
        this.msgOptions
      );
    }
  }

  getInstitutionStream(institutionId: string): Observable<any> {
    return this.serverSentStream(
      `${
        this.baseUrl
      }/${this._ts.getTenant()}/study/institution/${institutionId}/stream`
    );
  }

  reconnectFunc(url): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias,unicorn/no-this-assignment
    const scope = this;
    setTimeout(function trySetup(): void {
      scope.serverSentStream(url);
      scope.reconnectFrequencySeconds *= 2;
      if (scope.reconnectFrequencySeconds >= 64) {
        scope.reconnectFrequencySeconds = 64;
      }
    }, scope.reconnectFrequencySeconds * 1000);
  }

  serverSentStream(url: string): Observable<any> {
    const token = this._jwt.getToken();
    return new Observable((observer) => {
      let eventSource = new EventSourcePolyfill(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      eventSource.addEventListener("open", (ev) => {
        observer.next(ev);
      });

      eventSource.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        const streamResponse = { eventType: event.lastEventId, data };
        observer.next(streamResponse);

        // Close the stream if the sweep has been processed
        // if (streamResponse.eventType === "SWEEP_PROCESSED") eventSource.close();
      });

      eventSource.addEventListener("error", (error) => {
        eventSource.close();
        if (error.error.message.includes("No activity within")) {
          console.log("Stream timed out. Reconnecting.");
          eventSource = new EventSourcePolyfill(url, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          });
        } else {
          observer.error(error);
        }
      });
    });
  }

  getDashboardReportData(institutionId: string): Promise<any> {
    const url = `${
      this.baseUrl
    }/${this._ts.getTenant()}/study/report/dashboard/${institutionId}`;
    return new Promise((resolve) => {
      this._http
        .get(url)
        .toPromise()
        .then((res) => {
          if (res) {
            return resolve(res);
          }
          return resolve("");
        })
        .catch(() => {
          // TODO: Add this to logging service
        });
    });
  }

  /**
   * Returns an array of signed URLs for getting low resolution
   * base64 encoded sweeps in JSON format from S3.
   * @param studyId
   * @returns Array of URLs to low res sweep JSON files
   */
  async getAllReviewSweepUrls(studyId: string): Promise<string[]> {
    try {
      const lowResSweepUrls: string[] = (await this._http
        .get(
          `${
            this.baseUrl
          }/${this._ts.getTenant()}/sweep/lowRes/study/${studyId}`
        )
        .toPromise()) as string[];
      return lowResSweepUrls ? lowResSweepUrls : [];
    } catch (error) {
      console.error("Error in getAllReviewSweepUrls:", error);
    }
  }

  /**
   * Returns a low resolution sweep represented as JSON object with
   * base64 encoded frames from the signed URL provided.
   * @param signedUrl
   * @returns Low res sweep object
   */
  async getReviewSweep(signedUrl: string): Promise<LowResSweep> {
    try {
      const res = (await this._http.get(signedUrl).toPromise()) as LowResSweep;
      if (!res) return;
      return res;
    } catch (error) {
      console.error("Error in getReviewSweep:", error);
    }
  }

  //=============== Observables =================

  getPatientOrderObs(): Observable<PatientOrderForm> {
    return this.poObs;
  }

  getPatientReportObs(poID: string): Observable<IntakeReport> {
    const poUrl = `${this.baseUrl}/${this._ts.getTenant()}/study/${poID}`;
    return this._http.get(poUrl).pipe(
      shareReplay(1),
      catchError(this.handleError),
      map((data: PatientOrderForm) => {
        this.set(JSON.parse(JSON.stringify(data)));
        this.setStatusValue(data.status);
        this.setPregnancyStage(data.pregnancyStage);

        return new IntakeReport(data);
      })
    );
  }

  /**
   * gets the sweeps by studyId returns an Array of SweepsByDirection
   * @param studyId
   */
  getFramesByStudyIdObs(studyId: string): Observable<SweepsByDirection[]> {
    const url = `${
      this.baseUrl
    }/${this._ts.getTenant()}/frame/study/${studyId}`;
    return this._http.get(url).pipe(
      catchError(this.handleError),
      map((res: SweepsByDirection[]) => {
        return res;
      })
    );
  }

  /**
   * updateStudyStatusField$
   * The $ naming convention will be used to signify that this is an obervable the has retry and catch logic
   *
   * @param studyId
   * @param status
   */
  updateStudyStatusField$(
    studyId: string,
    status: string
  ): Observable<PatientOrderForm> {
    const body = {
      studyId,
      status,
    };
    const url = `${this.baseUrl}/${this._ts.getTenant()}/study/status`;
    if (status === "ORDER_COMPLETED") {
      // Get timezone for final report signature
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      body["timezone"] = tz.split("/").join("--"); // Replace slashes to pass as param in report URL
    }

    return this._http.patch(url, body).pipe(
      retryWhen((response) =>
        this._rxjsService.RetryWithEscalatingDelay(response)
      ),
      catchError((err) => this._rxjsService.handleErrors(err)),
      map((res: PatientOrderForm) => {
        this.setStatusValue(res.status);
        return res;
      })
    );
  }

  /**
   * updateOrderField$
   * The $ naming convention will be used to signify that this is an obervable the has retry and catch logic
   *
   * @param studyId
   * @param fields  an array of { name: string; value: any }
   */
  updateOrderField$(
    studyId: string,
    fields: { name: string; value: any }[]
  ): Observable<PatientOrderForm> {
    if (!studyId) {
      this.createOrder(this.patientOrder);
      return;
    }

    // Only update the fields that have a new value and if there are
    // not any new values then don't update
    fields.filter((f) => f.value !== this.patientOrder[f.name]);
    if (fields.length === 0) return;

    const url = `${this.baseUrl}/${this._ts.getTenant()}/study`;
    const body = { studyId, fields };

    return this._http.patch(url, body).pipe(
      retryWhen((response) =>
        this._rxjsService.RetryWithEscalatingDelay(response)
      ),
      catchError(this._rxjsService.handleErrors),
      map((res: PatientOrderForm) => {
        // Update in this fashion so that we don't overwrite changes
        // that were made while this update call was waiting
        const updates: any = {};
        fields.forEach((f) => {
          updates[f.name] = f.value;
        });
        this.set({
          ...this.patientOrder,
          ...updates,
        });
        this.setDueDateMethodology(res.dueDateMethodology);
        return res;
      })
    );
  }
}
